import {Component, Inject, OnDestroy, PLATFORM_ID} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {TranslateService} from '@ngx-translate/core';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  isBrowser: boolean;

  constructor(private storage: Storage,
              public translate: TranslateService,
              @Inject(PLATFORM_ID) private platformId: any) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.storage.create();
    this.translate.addLangs(['en', 'uk']);
    this.translate.setDefaultLang('en');
    this.checkUserLang();
  }

  ngOnDestroy() {
    return this.storage.clear();
  }

  private checkUserLang(): void {
    const browserLang = this.translate.getBrowserLang();
    if (this.isBrowser) {
      const matchedLang = browserLang.match(/ru|uk/) ? browserLang : 'uk';
      const userLanguage = localStorage.getItem('userLang');
      this.translate.use(userLanguage ? userLanguage : matchedLang);
    }

  }
}
