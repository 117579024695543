import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AngularFireAuthModule, USE_EMULATOR as USE_AUTH_EMULATOR} from '@angular/fire/compat/auth';
import {AngularFireDatabaseModule, USE_EMULATOR as USE_DATABASE_EMULATOR} from '@angular/fire/compat/database';
import {USE_EMULATOR as USE_FIRESTORE_EMULATOR} from '@angular/fire/compat/firestore';
import {USE_EMULATOR as USE_FUNCTIONS_EMULATOR} from '@angular/fire/compat/functions';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire/compat';
import {FIREBASE_OPTIONS} from '@angular/fire/compat';
import {IonicStorageModule} from '@ionic/storage-angular';
import {Axios} from 'axios';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgxEchartsModule} from 'ngx-echarts';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';

// AoT requires an exported function for factories for translate module
export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [{provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    // {provide: USE_AUTH_EMULATOR, useValue: environment?.useEmulators ? ['https://localhost', 9099] : undefined},
    // {provide: USE_DATABASE_EMULATOR, useValue: environment?.useEmulators ? ['https://localhost', 9000] : undefined},
    // {provide: USE_FIRESTORE_EMULATOR, useValue: environment?.useEmulators ? ['https://localhost', 8080] : undefined},
    // {provide: USE_FUNCTIONS_EMULATOR, useValue: environment?.useEmulators ? ['https://localhost', 5001] : undefined},
    {provide: FIREBASE_OPTIONS, useValue: environment?.firebaseConfig},
    {provide: Axios, useValue: {}}
    // AngularFireAuth
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
