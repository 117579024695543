// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  useEmulators: false,
  // baseUrl: 'http://localhost:8080',
  baseUrl: 'http://api.farca.one:8080',
  firebaseConfig: {
    apiKey: 'AIzaSyD4RuQpbfcU9wwDIGhjD2hYy0mG_OJ4azE',
    authDomain: 'aniroosh-com.firebaseapp.com',
    databaseURL: 'https://aniroosh-com-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'aniroosh-com',
    storageBucket: 'aniroosh-com.appspot.com',
    messagingSenderId: '1031474467999',
    appId: '1:1031474467999:web:10f93572a07f324d8cfeaa',
    measurementId: 'G-ZPVEDJSPXN',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
